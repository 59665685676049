import * as React from 'react';
import loadable from '@loadable/component';
import { InfoColumnsConfiguration, InfoColumnsItem, InfoColumnsLayoutType } from './interfaces/InfoColumns';
import styled from 'styled-components';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import ButtonLink from 'styleguide/components/Buttons/Button/ButtonLink';
import { media } from 'styleguide/helpers/media';
import { WidgetCommonProps } from './interfaces/interfaces';
const Markdown = loadable(() => import(/* webpackChunkName: "markdown" */ 'common/components/Markdown'));
import { styleguideImport } from 'utils/import';
import OddlySubTitle, { OddlySubTitleType } from '../Oddlygood/OddlySubTitle';

const InfoColumnsWrapper = styled.div`
	width: 100%;
	max-width: 1140px;
`;

const InfoColumnInner = styled.div`
	padding: 40px 0;
	margin: 0 ${({ theme }) => theme.grid.gutterInPx(2)};
	text-align: center;

	${media.desktop`
    margin: 0 auto;
    text-align: left;
  `};
`;

const TitleWrapper = styled.div`
	display: inline-block;

	> svg,
	> img,
	> span {
		width: auto;
		height: auto;
		vertical-align: middle;
		margin-left: -15px;
	}

	> svg {
		width: 32px;
		height: 32px;

		path {
			fill: ${({ theme }) => theme.colors.brandPrimary};
		}
	}

	> h2 {
		display: inline-block;
		font-size: 32px;
		line-height: 1;
		vertical-align: middle;
	}

	${media.desktop`
    > svg {
      width: 48px;
		  height: 48px;  
    }
    > h2 {
      font-size: 48px;
      line-height: 1;
    }    
  `};
`;

interface ColumnsContainerProps {
	hasBackground?: boolean;
}

const ColumnsContainer = styled.div<ColumnsContainerProps>`
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-content: flex-start;
	background: ${p => (p.hasBackground ? p.theme.colors.contentSectionHiglighted : 'inherit')};
	padding: ${p => (p.hasBackground ? '1em 2em 1em 2em' : '')};
`;

interface ColumnWrapperProps {
	columnCount: number;
}

const CalculateColumnWidths = (props: ColumnWrapperProps) => {
	if (props.columnCount === 1) {
		return 50;
	} else if (props.columnCount === 2) {
		return 49;
	} else if (props.columnCount === 3) {
		return 33;
	} else {
		return 25;
	}
};

const ColumnWrapper = styled.div`
	flex: 0 1 100%;
	position: relative;
	padding-bottom: 75px;
	margin-bottom: ${({ theme }) => theme.grid.gutterInPx(4)};
	text-align: center;

	${media.tablet`
    flex: 0 1 calc(50% - 16px);
    max-width: calc(50% - 16px);
    text-align: left;

    &:nth-child(even) {
      border-left: 1px solid ${({ theme }) => theme.colors.border};
      padding-left: ${({ theme }) => theme.grid.gutterInPx(2)};
      margin-left: ${({ theme }) => theme.grid.gutterInPx(2)};
    }
  `};

	${media.desktop`
    flex: 0 1 calc(${(props: ColumnWrapperProps) => `${CalculateColumnWidths(props)}%`} - 16px);
    max-width: calc(${(props: ColumnWrapperProps) => `${CalculateColumnWidths(props)}%`} - 16px);
    & + & {
      border-left: 1px solid ${({ theme }) => theme.colors.border};
      padding-left: ${({ theme }) => theme.grid.gutterInPx(2)};
      margin-left: ${({ theme }) => theme.grid.gutterInPx(2)};
    }
  `};
`;

const ColumnTitle = styled.h3`
	font-size: 16px;
	line-height: 1.25;
	font-weight: 900;
	color: ${({ theme }) => theme.colors.brandPrimary};

	${media.tablet`
    font-size: 20px;
    line-height: 1.2;
  `};
`;

const ColumnContent = styled.div`
	font-size: 16px;
	line-height: 1.5;
`;

const ColumnButton = styled(ButtonLink)`
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 275px;

	${media.tablet`
    width: auto;
    left: auto;
    transform: none;
  `};
`;

interface InfoColumnState {
	infoColumnIcon: React.FC | React.ComponentClass | null;
}

class InfoColumns extends React.Component<WidgetCommonProps & InfoColumnsConfiguration, InfoColumnState> {
	public state: InfoColumnState = {
		infoColumnIcon: null,
	};

	public async componentDidMount() {
		const icon = await styleguideImport(this.props.icon);
		if (icon) {
			this.setState({ infoColumnIcon: icon });
		}
	}

	public render() {
		const { title } = this.props;
		const layout = this.props.layoutName || InfoColumnsLayoutType.default;
		const { infoColumnIcon } = this.state;

		let Icon: any = 'span';
		if (infoColumnIcon) {
			Icon = infoColumnIcon;
		}

		const regularTitle = () => {
			return (
				<TitleWrapper>
					{!!Icon && <Icon />}{' '}
					<MediumHeader noTextTransform={layout === InfoColumnsLayoutType.withBackground}>{title}</MediumHeader>
				</TitleWrapper>
			);
		};

		const oddlyTitle = () => {
			return <OddlySubTitle type={OddlySubTitleType.Primary} title={title} />;
		};

		const titleRenderer = layout === InfoColumnsLayoutType.oddlygood ? oddlyTitle : regularTitle;

		return (
			<InfoColumnsWrapper id={this.props.widgetId}>
				<InfoColumnInner>
					{titleRenderer()}
					<ColumnsContainer hasBackground={layout === InfoColumnsLayoutType.withBackground}>
						{this.renderItems()}
					</ColumnsContainer>
				</InfoColumnInner>
			</InfoColumnsWrapper>
		);
	}

	private renderItems() {
		const { items } = this.props;
		return items.map((item, index) => this.renderItem(item, index, items.length));
	}

	private renderItem = ({ title, content, linkUrl, linkText }: InfoColumnsItem, index: number, count: number) => {
		return (
			<ColumnWrapper key={index} columnCount={count}>
				<ColumnTitle>{title}</ColumnTitle>
				<ColumnContent>
					<Markdown source={content} escapeHtml={true} />
				</ColumnContent>
				{linkText && linkUrl && <ColumnButton href={linkUrl}>{linkText}</ColumnButton>}
			</ColumnWrapper>
		);
	};
}

export default InfoColumns;
