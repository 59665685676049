import * as React from 'react';
import loadable from '@loadable/component';
import styled, { DefaultTheme } from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { TableConfiguration } from './interfaces/Table';
const Markdown = loadable(() => import(/* webpackChunkName: "markdown" */ '../Markdown'));
import { WIDGET_SPACING_SMALL } from './constants';
import { ContentThemeContext } from '../../contexts/ContentThemeContext';
import { WidgetCommonProps } from './interfaces/interfaces';
import { contentLinkStyle } from '../General';

type TableLayoutName = Pick<TableConfiguration, 'layoutName'>;
interface TableWrapperProps extends TableLayoutName {
	color: string;
}

const getColor = (theme: DefaultTheme, color?: string): string => {
	return color ? color : theme.colors.brandPrimary;
};

const TableWrapper = styled.article<TableWrapperProps>`
	width: 100%;
	max-width: 850px;
	margin: ${WIDGET_SPACING_SMALL}px auto;
	overflow-x: auto;

	${media.tablet`
		overflow-x: unset;
	`};

	table {
		width: 100%;
		border-collapse: separate;
		border-spacing: 0;
		border: 1px solid ${({ theme, color }) => getColor(theme, color)};
		border-radius: 4px;

		${media.tablet<{ layoutName?: string }>`
			table-layout: ${props => (props.layoutName && props.layoutName === 'autoCellWidth' ? 'auto' : 'fixed')} ;
		`};
	}

	th {
		background: ${({ theme, color }) => getColor(theme, color)};
		color: ${props => props.theme.colors.white};
		font-size: 15px;
		font-weight: 600;
		text-align: left;
		padding: 10px 20px;
	}

	tbody {
	}

	td {
		vertical-align: top;
		padding: 10px 20px;
		border-top: 1px solid ${({ theme, color }) => getColor(theme, color)};
		border-left: 1px solid ${({ theme, color }) => getColor(theme, color)};
		font-size: 15px;
		line-height: 1.33;
		// word-break: break-word;

		&:first-child {
			border-left: none;
		}
	}

	a {
		${({ color, theme }) => contentLinkStyle(theme, color)};
	}
`;

const Table: React.FC<WidgetCommonProps & TableConfiguration> = ({ widgetId, content, layoutName }) => {
	return (
		<ContentThemeContext.Consumer>
			{contentTheme => (
				<TableWrapper id={widgetId} color={contentTheme.color} layoutName={layoutName}>
					<Markdown source={content} escapeHtml={true} />
				</TableWrapper>
			)}
		</ContentThemeContext.Consumer>
	);
};

export default Table;
