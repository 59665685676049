import * as React from 'react';
import loadable from '@loadable/component';
import styled, { css } from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { TextConfiguration } from './interfaces/Text';
import { ContentTheme, ContentThemeContext } from 'common/contexts/ContentThemeContext';
const Markdown = loadable(() => import(/* webpackChunkName: "markdown" */ 'common/components/Markdown'));
import { contentLinkStyle } from '../General';
import { WIDGET_SPACING_SMALL, WIDGET_SPACING_MEDIUM, TEXT_WIDTH_NARROW, TEXT_WIDTH } from './constants';
import { WidgetCommonProps } from './interfaces/interfaces';
import kebabCase from 'lodash/kebabCase';
import { MapStateToProps, connect } from 'react-redux';
import { State } from 'common/reducers';
import { getSiteUrlPrefix } from '../App/services';

type TextWrapperProps = ContentTheme & Partial<TextConfiguration>;

export const TextStyles = css<ContentTheme>`
	font-family: ${props => props.theme.fonts.secondary};
	color: ${props => props.theme.colors.bodyText};
	margin: ${WIDGET_SPACING_SMALL}px auto;
	line-height: 1.33;

	p {
		margin: 0 0 20px;
	}

	hr {
		margin: ${WIDGET_SPACING_SMALL}px 0;
		border: none;
		background: ${props => props.theme.colors.border};
		height: 1px;

		${media.desktop`
      margin: ${WIDGET_SPACING_MEDIUM}px 0;
    `};
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		${({ theme }) => theme.typography.heading};
		font-size: 18px;
		line-height: 1;
		margin: 34px 0 14px;

		${media.desktop`
      font-size: 24px;
    `};
	}

	h1,
	h2,
	h3 {
		font-family: ${({ theme }) => theme.fonts.primary};
		color: ${({ color, theme }) => color || theme.colors.bodyText};
	}

	h4,
	h5,
	h6 {
		font-size: 15px;

		${media.desktop`
      font-size: 18px;
    `};
	}

	a {
		${({ theme, color }) => contentLinkStyle(theme, color)};
	}

	ul,
	ol {
		margin: 0 0 20px;
		padding-left: 30px;

		li {
			padding-left: 5px;

			& + li {
				margin-top: 14px;
			}
		}
	}
`;

export const TextWrapper = styled.div<TextWrapperProps>`
	width: 100%;
	max-width: ${TEXT_WIDTH_NARROW}px;
	font-size: 15px;
	padding: 0 ${({ theme }) => theme.grid.gutterInPx()};

	${({ theme }) => theme.styledVariations.textWidgetContentAlignment()};

	${media.tablet`
		padding: 0;
	`};

	${media.desktop`
    font-size: 18px;
    padding: 0;
  `};

	${media.desktop1440`
    max-width: ${TEXT_WIDTH}px;
  `};

	${TextStyles};

	${props => {
		if (props.layoutName === 'themed') {
			return css`
				ol {
					list-style: none;
					padding-left: 55px;

					${media.desktop`
  					padding-left: 60px;
          `};

					li {
						counter-increment: themed-counter;
						position: relative;
						padding: 7px 0 10px;
						margin: 0;
						border-bottom: 1px solid ${props.theme.colors.border};

						&:before {
							${({ theme }) => theme.typography.heading};
							content: counter(themed-counter);
							width: 35px;
							height: 35px;
							background: ${props.color};
							border-radius: 50%;
							color: ${props.theme.colors.white};
							font-size: 15px;
							text-align: center;
							line-height: 35px;
							display: block;
							position: absolute;
							left: -55px;
							top: 0;

							${media.desktop`
                width: 40px;
                height: 40px;
                line-height: 40px;
                left: -60px;
              `};
						}
					}
				}
			`;
		}
		if (props.layoutName === 'reference') {
			return css`
				ol {
					li {
						color: ${props.theme.colors.bodyText};
						font-style: italic;
						font-size: 14px;
						line-height: 1.25;
						padding-left: 15px;

						${media.desktop`
              font-size: 16px;
            `};
					}
				}
			`;
		}
		return null;
	}};
`;

type Props = WidgetCommonProps & TextConfiguration;

const Text: React.FC<Props & StateProps> = ({ widgetId, content, layoutName, escapeHtml, urlPrefix }) => {
	return (
		<ContentThemeContext.Consumer>
			{contentTheme => (
				<TextWrapper
					id={widgetId}
					color={contentTheme.color}
					layoutName={layoutName}
					className={`text-wrapper ${kebabCase(layoutName)}`}>
					<Markdown source={content} escapeHtml={escapeHtml} relativeUrlPrefix={urlPrefix} />
				</TextWrapper>
			)}
		</ContentThemeContext.Consumer>
	);
};

interface StateProps {
	urlPrefix: string | undefined;
}

const mapStateToProps: MapStateToProps<StateProps, Props, State> = ({ routing, app }) => {
	const sites = (app.settings && app.settings.sites) || [];
	const urlPrefix = getSiteUrlPrefix(routing, sites);

	return {
		urlPrefix,
	};
};

export default connect(mapStateToProps)(Text);
