import * as React from 'react';
import loadable from '@loadable/component';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { ContentTheme, ContentThemeContext } from 'common/contexts/ContentThemeContext';
const Markdown = loadable(() => import(/* webpackChunkName: "markdown" */ 'common/components/Markdown'));
import { WidgetCommonProps } from './interfaces/interfaces';
import { ArticleTextConfiguration } from './interfaces/ArticleText';
import { TextStyles } from './Text';
import kebabCase from 'lodash/kebabCase';

type ArticleTextWrapperProps = ContentTheme & Partial<ArticleTextConfiguration>;

const ArticleTextWrapper = styled('div')<ArticleTextWrapperProps>`
	${TextStyles};

	${({ layoutName }) =>
		layoutName === 'LeadText'
			? `
        font-size: 16px;
        font-weight: 600;
        line-height: 1.38;
        letter-spacing: 0.2px;
      `
			: `
        font-size: 14px;
      `}

	${media.tablet<ArticleTextWrapperProps>`
      ${({ layoutName }) =>
				layoutName === 'LeadText'
					? `
            font-size: 22px;
            line-height: 1.38;
            letter-spacing: normal;
          `
					: `
            font-size: 18px;
          `}
    `};
`;

const ArticleText: React.FC<WidgetCommonProps & ArticleTextConfiguration> = ({
	widgetId,
	content,
	escapeHtml,
	layoutName,
}) => {
	return (
		<ContentThemeContext.Consumer>
			{contentTheme => (
				<ArticleTextWrapper
					id={widgetId}
					color={contentTheme.color}
					className={`text-wrapper ${kebabCase(layoutName)}`}
					layoutName={layoutName}>
					<Markdown source={content} escapeHtml={escapeHtml} />
				</ArticleTextWrapper>
			)}
		</ContentThemeContext.Consumer>
	);
};

export default ArticleText;
