import * as React from 'react';
import loadable from '@loadable/component';
import styled from 'styled-components';
import SmallHeader from 'styleguide/components/Typography/SmallHeader/SmallHeader';
import { media } from 'styleguide/helpers/media';
import { TipConfiguration } from './interfaces/Tip';
import { ContentTheme, ContentThemeContext } from '../../contexts/ContentThemeContext';
const Markdown = loadable(() => import(/* webpackChunkName: "markdown" */ 'common/components/Markdown'));
import { WIDGET_SPACING_SMALL, WIDGET_SPACING_LARGE } from './constants';
import { contentLinkStyle } from '../General';
import { WidgetCommonProps } from './interfaces/interfaces';
import { Icon } from 'utils/import';

const SimpleTipWrapper = styled.section<ContentTheme>`
	width: 100%;
	max-width: 520px;
	margin: ${WIDGET_SPACING_SMALL}px auto;
	padding: 20px 10px 34px 0;
	display: flex;
	align-items: center;
	font-size: 18px;

	${media.tablet`
  	padding: 20px 0 34px;
  `};

	${media.desktop`
		margin: ${WIDGET_SPACING_LARGE}px auto;
	`};

	.small-header {
		margin-bottom: 4px;
		font-size: 16px;
	}

	p {
		margin: 0;
	}

	a {
		${({ color, theme }) => contentLinkStyle(theme, color)};
	}

	& > svg {
		flex: 0 0 80px;
	}
`;

const ThemedTipWrapper = styled.section<ContentTheme>`
	width: 100%;
	max-width: 850px;
	margin: 30px auto;
	border: 1px solid ${props => props.color};
	border-radius: 2px;
	border-left-width: 30px;
	padding: 30px;
	color: black;
	font-size: 15px;
	line-height: 1.33;

	${media.tablet`
  	padding: 30px 45px 30px 50px;
  `};

	${media.desktop`
  	font-size: 18px;
  `};

	header {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}

	p {
		margin: 0;

		& + p {
			margin-top: 14px;
		}
	}

	a {
		${({ color, theme }) => contentLinkStyle(theme, color)};
	}
`;

const IconWrapper = styled.div<{ hidden: boolean }>`
	display: ${props => (props.hidden ? 'none' : 'flex')};
	flex: 0 0 80px;
	height: 80px;
	margin: 0 20px 0 0;
	justify-content: center;
	align-items: center;

	svg {
		width: 55%;
		height: 55%;
	}
`;

const ThemedTipTitle = styled.h3<ContentTheme>`
	${({ theme }) => theme.typography.heading};

	font-size: 18px;
	line-height: 1.25;
	margin: 0;
	color: ${props => props.color};

	${media.desktop`
  	font-size: 24px;
  `};
`;

const Tip: React.FC<WidgetCommonProps & TipConfiguration> = ({ widgetId, title, content, layoutName, icon }) => {
	const renderThemed = () => {
		if (layoutName === 'themedWithoutIcon') {
			icon = undefined;
		}

		return (
			<ContentThemeContext.Consumer>
				{contentTheme => (
					<ThemedTipWrapper id={widgetId} color={contentTheme.color}>
						<header>
							<IconWrapper hidden={icon === undefined}>
								<Icon iconName={icon} color={contentTheme.color} />
							</IconWrapper>
							<ThemedTipTitle color={contentTheme.color}>{title}</ThemedTipTitle>
						</header>
						<Markdown source={content} />
					</ThemedTipWrapper>
				)}
			</ContentThemeContext.Consumer>
		);
	};

	const renderSimple = () => {
		return (
			<ContentThemeContext.Consumer>
				{contentTheme => (
					<SimpleTipWrapper color={contentTheme.color}>
						<IconWrapper hidden={false}>
							<Icon iconName={icon} color={contentTheme.color} />
						</IconWrapper>
						<div>
							<SmallHeader className="small-header">{title}</SmallHeader>
							<Markdown source={content} />
						</div>
					</SimpleTipWrapper>
				)}
			</ContentThemeContext.Consumer>
		);
	};

	return layoutName === 'themed' || layoutName === 'themedWithoutIcon' ? renderThemed() : renderSimple();
};

export default Tip;
