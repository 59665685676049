import { WidgetConfiguration } from './interfaces';

export interface InfoColumnsItem {
	title: string;
	content: string;
	linkUrl: string;
	linkText: string;
}

export enum InfoColumnsLayoutType {
	default = '',
	withBackground = 'withBackground',
	oddlygood = 'oddlygood',
}

export interface InfoColumnsConfiguration {
	icon: string;
	title: string;
	layoutName?: InfoColumnsLayoutType;
	items: InfoColumnsItem[];
}

export interface InfoColumnsWidget extends WidgetConfiguration {
	widgetName: 'InfoColumnsFrodget';
	configuration: InfoColumnsConfiguration;
}
